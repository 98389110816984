<template>
  <div class="copy-line-items-table">
    <div class="table-header-content">
      <template v-for="item in fields">
        <div
          :class="'table-header-titles--' + item.label"
          class="table-header-titles"
          :style="styles"
          :key="item.label"
        >
          {{ $t("accountTable." + item.label) }}
        </div>
      </template>
    </div>
    <div class="table-row-content">
      <div class="table-row-list" v-for="(line, index) in lineItems" :key="index">
        <div
          :class="
            line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
          "
          class="table-row-list__item--description"
        >
          <input
            class="input-information input-information--description"
            type="text"
            v-model="line.description"
            :placeholder="line.description"
          />
        </div>
        <div
          :class="
            line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
          "
          class="table-row-list__item--description nmfc-input"
        >
          <input
            class="input-information input-information--description"
            type="text"
            v-model="line.nmfc_number"
            :placeholder="line.nmfc_number"
            :disabled="!line.nmfcFieldEnabled"
          />
        </div>
        <div
          :class="
            line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
          "
        >
          {{ line.handling_unit_count }} {{ line.handling_units }}
        </div>
        <div
          :class="
            line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
          "
        >
          <p>
            {{ ceilDecimals(line.dimensions.length) }} x {{ ceilDecimals(line.dimensions.width) }} x
            {{ ceilDecimals(line.dimensions.height) }}
            ({{ line.dimensions.dimension_units }})
          </p>
        </div>
        <div
          :class="
            line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
          "
          class="table-row-list__item--weight"
        >
          {{ line.weight }} ({{ line.weight_units }})
        </div>
        <div
          :class="
            line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
          "
          class="table-row-list__item--nmfc_class"
        >
          {{ line.nmfc_class }}
        </div>
        <div
          :class="
            line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
          "
        >
          <input
            class="input-information input-information--description"
            min="0"
            type="number"
            v-model="line.packaging_unit_count"
            :placeholder="line.packaging_unit_count"
          />
        </div>
        <div
          :class="
            line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
          "
        >
          <div>
            <RCSelect
              class="packaging-units-dropdown"
              :title="$t('allOptions.pallets')"
              :suggestions="dropdownSuggestions"
              v-model="line.packaging_units"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RCSelect from "../../../../../components/RCComponents/CustomRCSelect.vue";

export default {
  name: "CopyLineItemsTable",
  components: {
    RCSelect,
  },
  data() {
    return {
      fields: [
        { label: "commodity" },
        { label: "nmfc" },
        { label: "handling_units" },
        { label: "dimensions" },
        { label: "weight" },
        { label: "nfm_class" },
        { label: "packaging_unit_count" },
        { label: "packaging_units" },
      ],
      dropdownSuggestions: [{ label: "Bags", value: "Bags" }],
      styles: {
        width: "14.58%",
      },
      lineItems: [
        {
          description: "Preview example",
          nmfc_number: null,
          handling_units: "Pallets",
          handling_unit_count: 1,
          packaging_units: "Bags",
          packaging_unit_count: 1,
          dimensions: {
            length: 48,
            width: 48,
            height: 48,
            dimension_units: "in",
          },
          weight: 300,
          weight_units: "lbs",
          nmfc_class: "175",
          id: "3316",
          line_item_id: "a0g7A000001PtmuQAC",
          nmfcFieldEnabled: false,
          nmfcPopover:
            // eslint-disable-next-line max-len
            "The carrier service selected was rated based on density, no NMFC code is required for this load.",
          hazmat_class_division: null,
          hazmat_number_type: null,
          hazmat_packaging_group: null,
          hazmat_number: null,
        },
      ],
    };
  },
  methods: {
    ceilDecimals(decimal) {
      return Math.ceil(decimal);
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-line-items-table {
  padding: 0px 15px;
}
.table-header {
  padding: 0px 15px;
}
.table-header-content {
  align-items: center;
  border-radius: 15px;
  border: 2px solid $color-border-container;
  background-color: $color-gray-light;
  color: $color-primary-company;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 50px;
}
.table-header-titles {
  @include font-small-standart-text;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  color: $color-primary-company;
  border-right: 2px solid $color-border-container;
  &:nth-last-child(1) {
    border-right: none;
  }
  &--weight {
    width: 7% !important;
  }
  &--nfm_class {
    width: 7% !important;
  }
  &--nmfc {
    width: 12% !important;
  }
  &--hazardous_materials {
    width: 10% !important;
  }
}
button {
  border: none;
  padding: 0;
  color: $color-primary-company;
  cursor: initial !important;
}
// Table row styles
.table-row-content {
  padding: 0px 15px;
}
.table-row-list {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  color: $color-primary-company;
  font-weight: 600;
  border-bottom: 1px solid $color-border-container;
  padding: 10px 0px;
  border-top: none;
  &__item {
    // width: 20.58% !important;
    &-more {
      @include hide-input-number-arrows;
      width: 14.58%;
    }
    &-lees {
      @include hide-input-number-arrows;
      width: 14.58%;
    }
    &--description {
      padding: 10px;
    }
    &--weight {
      width: 7% !important;
    }
    &--nmfc_class {
      width: 7% !important;
    }
    &--hazardous_materials {
      width: 10%;
    }
  }
  p {
    margin: 0 !important;
  }
}
.input-information {
  @include input-password;
  width: 100%;
  @include font-small-standart-text;
  border-radius: 10px;
  &--description {
    border: 1px solid $color-border-container;
    text-align: center;
    width: 100%;
  }
}
.nmfc-input {
  position: relative;
  width: 12% !important;
}
button {
  border: none;
  background: none;
  padding: 0;
  color: $color-primary-company;
  cursor: initial !important;
}
.dropdown-container {
  border: 1px solid $color-border-container;
  height: 33px;
  width: 88%;
  margin: auto;
}
::v-deep .packaging-units-dropdown {
  width: 90%;
  margin-left: 15px;
  font-weight: normal;
  & .rc-select-container {
    height: 33px;
  }
  & .rc-floating-label,
  .rc-select,
  .rc-suggestions-list {
    font-size: 14px;
  }
  & .rc-suggestions {
    top: 30px;
  }
}
</style>
