<template>
  <div class="document-type">
    <ul class="document-tabs">
      <li
        class="document-tab__item"
        v-for="(tab, index) in filteredTabs"
        :key="index"
        :label="tab.label"
        :name="tab.name"
        :class="{ is_active: activeButton === tab.name }"
      >
        <button
          class="document-tab-button"
          @click="[filterDocumentByType(tab), (activeButton = tab.name)]"
        >
          {{ tab.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable max-len */
export default {
  name: "DocumentTypeToolbar",
  props: {
    role: String,
    chosenLoad: Object,
    documents: {
      type: Array,
      default: () => [],
    },
    invoiceDocument: {
      type: Array,
      default: () => [],
    },
    tokenInfo: Object,
  },
  data() {
    return {
      activeButton: "allDocs",
      keyTabs: 0,
      parcelModeId: "a0k1I00000B6pAYQAZ",
      ltlModeId: "a0k1I0000005NnyQAE",
      ltlCanadaModeId: "a0k3s00000aZwb3AAC",
      tabs: [
        {
          label: "ALL DOCS",
          name: "allDocs",
          condition: () => true,
        },
        {
          label: "MY DOCS",
          name: "/portal-tender-documents/",
          condition: () => true,
        },
        {
          label: this.$t("myLoads.myLoadsDetail.bol"),
          name: "/bol/",
          condition: () => this.chosenLoad.mode_id !== this.parcelModeId && this.verifyDocumentExistence("bol"),
        },
        {
          label: this.$t("myLoads.myLoadsDetail.labels"),
          name: "labels",
          condition: () => this.verifyDocumentExistence("shipping_labels") || this.verifyDocumentExistence("shiping-labels"),
        },
        {
          label: this.$t("myLoads.myLoadsDetail.pod"),
          name: "DELIVERY_RECEIPT",
          condition: () => this.enablePodDocument,
        },
        {
          label: "SIGNED BOL",
          name: "BILL_OF_LADING",
          condition: () => this.chosenLoad.load_status === "Completed" &&
            this.verifyDocumentExistence("BILL_OF_LADING"),
        },
        {
          label: "WEIGHT CERTIFICATE",
          name: "WEIGHT_CERTIFICATE",
          condition: () => this.verifyDocumentExistence("WEIGHT_CERTIFICATE"),
        },
        {
          label: "INVOICE",
          name: "invoice",
          condition: () => this.invoiceDocument[0] && !this.tokenInfo.is_branded,
        },
      ],
    };
  },
  created() {
    const tab = {
      name: "allDocs",
    };
    this.filterDocumentByType(tab, false);
  },
  computed: {
    enablePodDocument() {
      return (
        (this.chosenLoad.mode_id === this.ltlModeId ||
          this.chosenLoad.mode_id === this.ltlCanadaModeId) &&
        (this.chosenLoad.load_status === "Completed" || this.chosenLoad.load_status === "Delivered")
      );
    },
    filteredTabs() {
      return this.tabs.filter((tab) => tab.condition());
    },
  },
  methods: {
    filterDocumentByType(tab) {
      const type = tab.name;
      this.activeButton = type;
      const payload = {
        type,
      };
      payload.executedGenerateDocument = true;
      if (type === "allDocs" || type === "/portal-tender-documents/") {
        payload.previewMode = true;
        payload.executedGenerateDocument = false;
      } else {
        payload.previewMode = true;
      }
      this.$emit("buttonClicked", payload);
    },
    verifyDocumentExistence(type) {
      return this.documents.some((document) => document.url.includes(type));
    },
  },
};
</script>

<style lang="scss" scoped>
.document-type {
  width: 100%;
  margin-bottom: 10px;
  &__button {
    border: 1px solid $color-border-container;
    background-color: $color-white;
    color: $color-primary-company;
    font-size: 10px;
    border-radius: 6px;
    padding: 2px 6px;
    margin-right: 5px;
    width: 20%;
    height: 30px;
  }
}
.document-tabs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 5px;
  margin: 0;
  padding: 0;
}
.document-tab-button {
  @include button-tag();
}
.is_active .document-tab-button {
  background-color: $color-border-container;
  color: $color-white;
}
</style>
