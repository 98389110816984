<template>
  <div class="copy-claim-or-dispute">
    <div class="claim-or-dispute-container">
      <div class="claim-content">
        <span class="claim-content__text">Do you have any billing inquiry ?</span>
        <button class="submit-button">Submit</button>
      </div>
      <div class="claim-content">
        <span class="claim-content__text">Is your freight lost or damaged ?</span>
        <button class="review-button">Review</button>
      </div>
    </div>
    <div class="button-content">
      <button class="secondary-button">SEND FILE</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyClaimOrDispute",
};
</script>

<style lang="scss" scoped>
// Claim or dispute
.claim-or-dispute-container {
  display: flex;
  padding: 20px 0px;
}
.claim-content {
  display: flex;
  flex-direction: column;
  margin: 0px 15px;
  &__text {
    border-top: 2px solid $color-border-container;
    margin-bottom: 10px;
    padding-top: 8px;
    color: $color-primary-company;
    font-weight: bold;
  }
}
.submit-button {
  background-color: $color-primary-trigger-button;
  color: $color-primary-text-button;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  padding: 2% 5%;
  width: min-content;
}
.review-button {
  background-color: $color-primary-trigger-button;
  color: $color-primary-text-button;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  padding: 2% 5%;
  width: min-content;
}
// Secondary button
.button-content {
  text-align: start;
  padding: 30px 15px;
  padding-top: 5px
}
.secondary-button {
  @include secondary-button;
  width: 200px;
}
</style>
