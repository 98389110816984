<template>
  <div class="theme-maker">
    <div class="theme-maker__back" @click="changeView('Themes')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>Back to themes</span>
    </div>
    <div class="theme-maker__content" v-if="screenWidth >= 801">
      <b-tabs card no-fade content-class="mt-3">
        <b-tab
          v-for="(category, index) in Object.keys(currentTheme.variables)"
          :key="index"
          :title="formatCategoryName(category)"
          @click="changeChosenSection(category)"
        >
          <small class="theme-maker__hover-disclaimer"
            ><i>*Hover colors are generated after saving the changes.</i></small
          >
          <Variables
            :ref="category"
            :colors="currentTheme.variables[category]"
            :category="category"
            @colorChange="getColorsSettings"
          />
        </b-tab>
        <b-tab title="Logo" @click="changeChosenSection('logo')">
          <div class="logo-tab-container">
            <DragAndDropSidebarLogo></DragAndDropSidebarLogo>
          </div>
        </b-tab>
      </b-tabs>
      <button
        v-loading="updatingTheme"
        @click="saveThemeSettings()"
        class="save-button"
        v-if="!currentAndNewThemeAreEqual"
      >
        Save
      </button>
    </div>
    <div class="theme-maker__content" v-if="screenWidth < 801">
      <div class="demo-collapse">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            :name="category"
            v-for="(category, index) in Object.keys(currentTheme.variables)"
            :key="index"
            :title="formatCategoryName(category)"
          >
            <Variables
              :ref="category"
              :colors="currentTheme.variables[category]"
              :category="category"
              @colorChange="getColorsSettings"
            />
          </el-collapse-item>
          <button
            @click="saveThemeSettings()"
            class="save-button"
            v-if="!currentAndNewThemeAreEqual"
          >
            Save
          </button>
        </el-collapse>
      </div>
    </div>
    <Preview v-if="renderPreview()" />
  </div>
</template>

<script>
import { getThemeVariables, changeTheme } from "@/utils/Themes";
import { generateHoverColor } from "@/utils/ModifyColorBrightness";
import onResize from "@/Extend/onResize";
import Variables from "./Variables.vue";
import Preview from "./Preview.vue";
import DragAndDropSidebarLogo from "./components/DragAndDropSidebarLogo.vue";

export default {
  name: "ThemeMaker",
  components: {
    Variables,
    Preview,
    DragAndDropSidebarLogo,
  },
  mixins: [onResize],
  data() {
    return {
      accountSettings: {},
      activeName: "buttons",
      themeColors: {},
      isModifiedColorsSettings: false,
      newSettingThemeColor: {},
      currentTheme: null,
      currentAndNewThemeAreEqual: true,
      chosenSection: "",
      updatingTheme: false,
    };
  },
  // Hook to prevent user from leaving the page without saving the changes
  async beforeRouteLeave(to, from, next) {
    if (!this.currentAndNewThemeAreEqual) {
      const swalResponse = await this.swal({
        title: "Are you sure?",
        text: "You have unsaved changes. Are you sure you want to leave?",
        icon: "warning",
        buttons: {
          cancel: "Discard changes",
          save: {
            text: "Save changes",
            value: "save",
          },
        },
        dangerMode: true,
      });
      if (swalResponse === "save") {
        this.saveThemeSettings();
        return;
      }
    }
    next();
  },
  created() {
    this.accountSettings = JSON.parse(localStorage.getItem("user-settings"));
    this.currentTheme = this.$store.getters["color/getThemes"].find(
      (theme) => theme.name === this.$route.params.theme
    );
    changeTheme(getThemeVariables(this.currentTheme));
    this.$store.commit("color/setNewThemeSchema", this.currentTheme);
    delete this.currentTheme.variables.checkboxes;
  },
  beforeDestroy() {
    this.themesList = this.$store.getters["color/getThemes"];
    this.chosenTheme = this.$store.getters["color/getChosenTheme"];
    this.defaultChosenTheme = this.chosenTheme;
    const currentTheme = this.themesList.find((theme) => theme.name === this.chosenTheme);
    changeTheme(getThemeVariables(currentTheme));
  },
  methods: {
    changeChosenSection(section) {
      this.chosenSection = section;
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
    formatCategoryName(name) {
      const capitalizedWord = name
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("_");
      return capitalizedWord.replace(/_/g, " ");
    },
    getColorsSettings() {
      const currentThemeVariables = this.getThemeVariables(
        this.$store.getters["color/getThemes"].find(
          (theme) => theme.name === this.$route.params.theme
        ).variables
      );
      const newThemeVariables = this.getThemeVariables(
        this.$store.getters["color/getNewTheme"].variables
      );
      this.currentAndNewThemeAreEqual = this.compareThemes(
        currentThemeVariables,
        newThemeVariables
      );
    },
    compareThemes(currentTheme, newTheme) {
      let isEqual = true;
      const variablesKeys = Object.keys(currentTheme);
      variablesKeys.forEach((variable) => {
        if (currentTheme[variable].toLowerCase() !== newTheme[variable].toLowerCase()) {
          isEqual = false;
        }
      });
      return isEqual;
    },
    getThemeVariables(theme) {
      const variables = {};
      // Transform response to object of colors to set the theme
      Object.keys(theme).forEach((category) => {
        theme[category].forEach((variable) => {
          variables[variable.variable_name] = variable.color;
        });
      });
      return variables;
    },
    async saveThemeSettings() {
      if (this.$options.filters.checkPermission("patch:account-settings")) {
        this.updatingTheme = true;
        const id = this.$store.getters["login/getTokenInfo"].account_id;
        const themes = this.updateLocalThemeVariables();
        const settingsSchema = JSON.parse(localStorage.getItem("user-settings"));
        settingsSchema.themes = themes;
        Object.assign(settingsSchema);
        await this.$store.dispatch("account/updateAccountSettings", {
          id: { account_id: id },
          settings: settingsSchema,
        });
        this.currentAndNewThemeAreEqual = true;
        this.$message({
          type: "success",
          message: "Theme updated successfully. Refresh the page to see all changes.",
          duration: 4000,
        });
        localStorage.setItem("user-settings", JSON.stringify(settingsSchema));
        this.updatingTheme = false;
      }
    },
    updateLocalThemeVariables() {
      const themes = this.$store.getters["color/getThemes"];
      const newThemeSchema = this.$store.getters["color/getNewTheme"];
      const newTheme = themes.findIndex((theme) => theme.name === newThemeSchema.name);
      themes[newTheme] = this.generateHoverColors(newThemeSchema);
      this.$store.commit("color/setThemes", themes);
      return themes;
    },
    generateHoverColors(newThemeSchema) {
      const themeSchema = JSON.parse(JSON.stringify(newThemeSchema));
      const colors = [
        {
          category: "buttons",
          colorVariable: "--color-primary-trigger-button",
          hoverColorVariable: "--color-primary-trigger-button-hover",
        },
        {
          category: "buttons",
          colorVariable: "--color-secondary-button",
          hoverColorVariable: "--color-secondary-button-hover",
        },
        {
          category: "buttons",
          colorVariable: "--color-carrier-quote-button",
          hoverColorVariable: "--color-carrier-quote-button-hover",
        },
        {
          category: "buttons",
          colorVariable: "--color-carrier-quote-insurance-button",
          hoverColorVariable: "--color-carrier-quote-insurance-button-hover",
        },
        {
          category: "buttons",
          colorVariable: "--color-button-tag",
          hoverColorVariable: "--color-button-tag-hover",
        },
        // {
        //   category: "buttons",
        //   colorVariable: "--color-review-button",
        //   hoverColorVariable: "--color-review-button-hover",
        // },
        // {
        //   category: "buttons",
        //   colorVariable: "--color-submit-button",
        //   hoverColorVariable: "--color-submit-button-hover",
        // },
        {
          category: "sidebar",
          colorVariable: "--color-sidebar",
          hoverColorVariable: "--color-sidebar-option-hover",
        },
        // {
        //   category: "checkboxes",
        //   colorVariable: "--color-selected-button-filter",
        //   hoverColorVariable: "--color-selected-button-filter-hover",
        // },
      ];
      colors.forEach((color) => {
        const colorValue = themeSchema.variables[color.category].find(
          (variable) => variable.variable_name === color.colorVariable
        ).color;
        const hoverColorValue = generateHoverColor(colorValue);
        themeSchema.variables[color.category].find(
          (variable) => variable.variable_name === color.hoverColorVariable
        ).color = hoverColorValue;
      });
      return themeSchema;
    },
    renderPreview() {
      const otherCategories = ["logo", "templates"];
      if (otherCategories.includes(this.chosenSection)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-maker {
  @include splitted-view-styles;
  padding-top: 4%;
  padding-bottom: 20px;

  &__hover-disclaimer {
    text-align: left;
    margin-bottom: 15px;
  }

  &__back {
    display: flex;
    align-items: center;
    margin-left: 1%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;

    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  &__content {
    display: block;
    padding: 20px;
    padding-bottom: 5px;
  }
}

.collapse {
  &-container {
    text-align: start;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Montserrat";
    color: $color-primary-company;
    font-weight: bold;
    justify-content: space-between;

    &__title {
      margin: 0px;
    }

    &__icon {
      font-size: 20px;
    }
  }
}

.save-button {
  margin: 25px auto 0px auto;
  display: flex;
  justify-content: space-around;
  @include secondary-button;
  background-color: $color-save-button;
  color: $color-white;
  border: none;

  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }

  width: 20%;
  text-align: center;
  padding: 7px;
}

///****Tabs styles****\\\\

::v-deep {
  .card-header {
    background-color: $color-white;
    border-bottom: 0px;
    padding: 12px 9.8px;
  }

  .nav-tabs .nav-link {
    text-align: start;
    background-color: $color-white;
    color: $color-primary-company;
    font-family: $font-family-portal;
    padding: 10px;

    &:hover {
      border: none;
    }
  }

  .tab-content > .active {
    display: flex;
    flex-direction: column;
  }

  .nav-tabs .nav-link.active {
    font-weight: bold;
    border: none;
    border-bottom: 3px solid $color-primary-company;
  }

  .el-collapse {
    border-top: none;
    border-bottom: none;
  }

  .el-collapse-item__header {
    color: $color-primary-company;
    font-size: 1rem;
    border-bottom: none;

    &.is-active {
      font-weight: bold;
    }

    i {
      font-weight: bold;
    }
  }

  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .lv-colorpicker__colorblock-wrap {
    right: 7px;
  }
}
</style>
