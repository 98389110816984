<template>
  <div class="branding-preview">
    <div class="branding-preview__app-container">
      <b-tabs card no-fade nav-wrapper-class>
        <b-tab title="Preview">
          <div class="tab-preview-content">
            <DocumentTypeToolbar :chosenLoad="loadById"/>
            <Stops />
            <CarrierOptions
              v-if="screenWidth > 800"
              :carrier="carrierInfo.carrier"
              :carrierImage="carrierInfo.carrierImage"
              :aditionalInsurance="carrierInfo.aditionalInsurance"
              :loadingInsurance="carrierInfo.loadingInsurance"
            />
            <CarrierResponsiveOptions
              v-if="screenWidth <= 800"
              :carrier="carrierInfo.carrier"
              :carrierImage="carrierInfo.carrierImage"
              :aditionalInsurance="carrierInfo.aditionalInsurance"
              :loadingInsurance="carrierInfo.loadingInsurance"
            />
            <CopyComponentClaimOrDispute />
            <CopyLineItemsTable v-if="screenWidth > 1000" />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import PreviewData from "@/Extend/PreviewData";
import onReSize from "@/Extend/onResize";
import CopyComponentClaimOrDispute from "./components/copyClaimOrDispute.vue";
import CopyLineItemsTable from "./components/copyLineItemsTable.vue";
import CarrierOptions from "../../../QuoteBook/CarrierSelection/carriersList/components/Sections/Carriers/OptionsCarriers.vue";
import CarrierResponsiveOptions from "../../../QuoteBook/CarrierSelection/carriersList/components/Sections/Carriers/OptionsCarriersResponsive.vue";
import Stops from "../../../QuoteBook/LoadCreation/stops/Index.vue";
import DocumentTypeToolbar from "../../../LoadDetails/components/documents/DocumentTypeToolbar.vue";

export default {
  name: "BrandingPreview",
  mixins: [PreviewData, onReSize],
  components: {
    Stops,
    CarrierOptions,
    DocumentTypeToolbar,
    CarrierResponsiveOptions,
    CopyComponentClaimOrDispute,
    CopyLineItemsTable
  },
};
</script>

<style scoped lang="scss">
.branding-preview {
  pointer-events: none;
  position: relative;
  z-index: 1;
  display: flex;
  height: 600px;
  // border: 1px solid black;
  margin-top: 5px;
  &__app-container {
    width: 100%;
    padding: 0px 20px;
  }
}
.tab-preview-content {
  padding: 20px;
  padding-top: 0px;
}

.carrier-card {
  margin: 20px 0px;
}

::v-deep .card-header {
  background-color: $color-white;
  border-bottom: 0px;
  padding: 12px 9.8px;
}
::v-deep .card-body {
  background: $color-gray-light;
  border: 1px solid $color-border-container;
  border-radius: 0px 10px 10px 10px;
  font-family: $font-family-portal;
  padding: 2% 0%;
}
.branding-preview .branding-preview__app-container ::v-deep .nav-tabs .nav-link {
  color: $color-primary-company;
  font-family: $font-family-portal;
  @include font-small-information;
  background: $color-gray-light;
  padding: 15px 30px;
  border: 1px solid $color-border-container;
  border-bottom: none;
}
::v-deep .nav-tabs > li {
  &:first-child > a {
    border-top-left-radius: 20px;
  }
  &:last-child > a {
    border-top-right-radius: 20px;
  }
}

// LineItems data
::v-deep .table-header-titles {
  display: block;
}
</style>
