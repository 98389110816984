<template>
  <div class="drag-and-drop-tab">
    <div class="drag-and-drop-container">
      <h3 class="drag-and-drop-container__title">Sidebar Logo</h3>
      <DragAndDrop v-model="appLogos.sidebarLogo" :imageSrc="appLogos.sidebarLogo" />
    </div>
    <button v-loading="updatingLogo" @click="saveThemeSettings()" class="save-button" v-if="!currentAndNewThemeAreEqual">
      Save
    </button>
  </div>
</template>

<script>
import DragAndDrop from "@/components/DragAndDrop.vue";

export default {
  name: "DragAndDropSidebarLogo",
  components: {
    DragAndDrop,
  },
  props: {
    logos: Object,
    documentsOptions: Object,
  },
  data() {
    return {
      appLogos: {
        sidebarLogo: "",
      },
      logosCopy: {},
      accountSettings: {},
      currentAndNewThemeAreEqual: true,
      updatingLogo: false,
    };
  },
  created() {
    this.assignmentSidebarLogo();
  },
  watch: {
    appLogos: {
      deep: true,
      handler(value) {
        if (value.sidebarLogo && value.sidebarLogo !== this.logosCopy.sidebarLogo) {
          this.currentAndNewThemeAreEqual = false;
        }
      },
    },
  },

  methods: {
    assignmentSidebarLogo() {
      this.accountSettings = JSON.parse(localStorage.getItem("user-settings"));
      const themeActual = this.$route.params.theme;
      for (let i = 0; i < this.accountSettings.themes.length; i += 1) {
        if (this.accountSettings.themes[i].name === themeActual) {
          const sidebarLogo = this.accountSettings.themes[i].logo;
          this.logosCopy = { sidebarLogo };
          this.appLogos = { ...this.logosCopy };
          break;
        }
      }
    },
    async saveThemeSettings() {
      if (this.$options.filters.checkPermission("patch:account-settings")) {
        this.updatingLogo = true;
        const id = this.$store.getters["login/getTokenInfo"].account_id;
        const settingsSchema = JSON.parse(localStorage.getItem("user-settings"));
        // Extract only the 'themes' property from the user settings
        const settingsSchemaThemes = { themes: settingsSchema.themes };
        // Get the currently selected theme from the route parameters
        const actualTheme = this.$route.params.theme;
        // Find the selected theme in the extracted themes
        const findTheme = settingsSchemaThemes.themes.find((theme) => theme.name === actualTheme);
        Object.assign(settingsSchema);
        this.currentAndNewThemeAreEqual = true;
        this.$message({
          type: "success",
          message: "Theme updated successfully. Refresh the page to see all changes.",
          duration: 4000,
        });
        // Update the sidebar logo and get the response
        const response = await this.updateSidebarLogo(id);
        // Update the logo in the main settings
        settingsSchema.logo = response.logo;
        // If the selected theme exists, update its logo as well
        if (findTheme) {
          findTheme.logo = response.logo;
        }
        await this.$store.dispatch("account/updateAccountSettings", {
          id: { account_id: id },
          settings: settingsSchema,
        });
        // Update the user settings in local storage
        localStorage.setItem("user-settings", JSON.stringify(settingsSchema));
        // Commit a mutation to trigger a sidebar logo render
        this.$store.commit("setRenderSidebarLogo");
        // Set updatingLogo back to false as logo update is complete
        this.updatingLogo = false;
      }
    },
    async updateSidebarLogo(id) {
      const imageFormData = new FormData();
      imageFormData.append("file", this.appLogos.sidebarLogo);
      return this.$store.dispatch("account/updateAccountLogo", {
        id,
        formData: imageFormData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Checkboxes.scss";

.drag-and-drop-container {
  text-align: left;

  &__title {
    @include font-section-title;
    margin-bottom: 20px;
    color: $color-primary-company;
  }

  &:first-child {
    margin-bottom: 30px;
  }
}

.drag-and-drop-container:nth-child(2) {
  ::v-deep {
    .drag-and-drop-content {
      width: 50%;

      @media (max-width: 850px) {
        width: 100%;
      }
    }
  }
}

.save-button {
  margin: 25px auto 0px auto;
  display: flex;
  justify-content: space-around;
  @include secondary-button;
  background-color: $color-save-button;
  color: $color-white;
  border: none;

  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }

  width: 20%;
  text-align: center;
  padding: 7px;
}

::v-deep {
  .drag-and-drop {
    min-width: none;
  }

  .custom-control-label {

    &:before,
    &:after {
      top: 0.1rem;
    }
  }

  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: $color-border-container;
  }
}
</style>
