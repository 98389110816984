<template>
  <div class="variables">
    <div class="variables-list" v-for="(color, index) in items" :key="index">
      <LvColorpicker
        v-if="color.display"
        :label="formatColorName(color.variable_name_shown)"
        v-model="color.color"
        @input="getNewColor(color.variable_name, $event)"
        hidePalette
      />
    </div>
  </div>
</template>

<script>
import LvColorpicker from "lightvue/color-picker";
import { setSidebarTextColor } from "../../../../utils/Themes";

export default {
  name: "Variables",
  components: {
    LvColorpicker,
  },
  props: {
    colors: Array,
    category: String,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.items = JSON.parse(JSON.stringify(this.colors));
  },
  methods: {
    getNewColor(colorName, newColor) {
      const root = document.documentElement;
      let newHexColor = newColor;
      if (newColor.match(/^rgb/)) {
        newHexColor = this.fromRgbToHex(this.getRgbValues(newColor));
      } else if (newColor.match(/^hsl/)) {
        newHexColor = this.fromHslToHex(this.getHslValues(newColor));
      }
      root.style.setProperty(colorName, newHexColor);
      if (colorName === "--color-sidebar") {
        setSidebarTextColor(root, newHexColor);
      }
      this.$store.commit("color/modifyNewTheme", {
        category: this.category,
        variable_name: colorName,
        color: newColor,
      });
      this.$emit("colorChange");
    },
    getRgbValues(color) {
      return color.match(/\((.*)\)/).pop();
    },
    fromRgbToHex(color) {
      const hexValues = color.split(",").map((value) => {
        const hex = Number(value).toString(16);
        if (hex.length < 2) {
          return `0${hex}`;
        }
        return hex;
      });
      return `#${hexValues.join("")}`;
    },
    getHslValues(color) {
      const hslValuesArray = color.match(/\((.*)\)/).pop();
      return hslValuesArray.split(",").map((value) => parseInt(value.trim(), 10));
    },
    fromHslToHex(hslColor) {
      const [h, s, l] = hslColor;
      let auxL = l;
      auxL /= 100;
      const a = (s * Math.min(auxL, 1 - auxL)) / 100;
      const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = auxL - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
          .toString(16)
          .padStart(2, "0");
      };
      return `#${f(0)}${f(8)}${f(4)}`;
    },
    formatColorName(name) {
      return name.replaceAll("-", " ").replaceAll("color", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.variables {
  text-align: start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  &-list {
    max-width: 230px;
    overflow: hidden;
    white-space: nowrap;
  }
}
::v-deep {
  .lv-colorpicker-wrapper {
    width: 100%;
  }
}
</style>
