/* eslint-disable */
export default {
  data() {
    return {
      showModalTerms: false,
      documents: [
        {
          name: "generated-docs/bol/SHP4289103.pdf",
          url: "https://storage.googleapis.com/charlie-portal-dev/generated-docs/bol/SHP4289103.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=charliebert%40charliebertvg.iam.gserviceaccount.com%2F20231013%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20231013T174757Z&X-Goog-Expires=604740&X-Goog-SignedHeaders=host&X-Goog-Signature=67d7c798434582b5f6dc60d01bd56b5e9208f29c212a3f09cfcc534260534be97d2ab7e5e1222d17854e171fc30600ba36072725d09880ea2def2117c7b4958a5d6730dce1804054fece28a6ed8de292e02d069e7054b026138ddc471bb0bacb08c2616790e118015deb4eea1d1b361970b4a210aa7aaff7ee05f2cb6a3156ea8e613cfef716ee3eac84ddb221adab80dc895fa8d048a45d542e81e82b039d9fbe5ed389ff323d8a08898083906746faee8c05943fb6d6e25853379ba9d7e3806a0b3e54d013eeca12940ccb91f9e74d6ac54a0e42e55eb345e1ca4dca0fa77aa3545704ce16b858798f12bc2c5c0331007500f9f9da5b32b2d57c9754c3e875",
        },
        {
          name: "generated-docs/shipping_labels/SHP4289103.pdf",
          url: "https://storage.googleapis.com/charlie-portal-dev/generated-docs/shipping_labels/SHP4289103.pdf?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=charliebert%40charliebertvg.iam.gserviceaccount.com%2F20231013%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20231013T174757Z&X-Goog-Expires=604740&X-Goog-SignedHeaders=host&X-Goog-Signature=02b90694670703c2b6e16b3dd84f64e554a083c788f68bc6ba335da525afef778b895e55f76574f2f6e978183e409ca2f1043d501820fbb4d59162f6569c769d437d6fe70e156ae19437753bab3a31b9fa2e1ab7a96879ca54b14f7c3acbf8025cfaad6163df199ff6e9081a7866275d3039dcef082166b2cd8c8ef75c82226276140b1dcf35137c46b5a92ec6b71cab442447023509f3052815e3a809475b774f148ade95a600ff25a0437d12eccef6d8901cc32b1c08eb7568cf12e4e80866d22c92c9e7fe06b5fdc6f184511a64dd24b216a7bda33ffb400b7fa48ecb367e519114699d03ea1e99cac627baaadf7f5939185e0a526483dec12e8305bf0c81",
        },
      ],
      carrierInfo: {
        carrier: {
          source: "revenova",
          carrierPostgresId: null,
          usedLiability: 30,
          transitTime: 2,
          serviceClass: "Standard Rate",
          rating: null,
          loadId: "a0j7A000007aJ13QAE",
          quoteNumber: null,
          quoteContractId: "SAIA_BA:136658106",
          price: 250.37,
          newLiability: 1500,
          modeName: null,
          loadNumber: "SHP3065828",
          estimatedDelivery: null,
          directInterline: null,
          currencyCode: "USD",
          carrier: "SAIA Motor Freight (LTL)",
          pickupPerformance: 82.85,
          deliveryPerformance: 75,
          principalCarrier: false,
          trackers: [],
          expiration: "2023-01-20",
          salesforceId: null,
          rateIds: [],
          shipmentIds: [],
          _id: "63c158fa4534ca80ed3df532",
          is_expired: false,
          createdAt: "2023-01-13T13:13:30.672Z",
          name: "SAIA Motor Freight (LTL)",
          description:
            "No Amazon deliveries. Will call fee will apply for shipments picked up at carriers' terminals/docks. Rates valid for 7 days.",
          alert: true,
        },
        carrierImage: {
          name: "carriers/SAIA Motor Freight (LTL).png",
          url: require("../assets/images/carriers_Southeastern Freight (LTL).png"),
        },
        aditionalInsurance: 35,
        loadingInsurance: false,
      },
      loadById: {
        id: "a0j3s00000EaNZqAAN",
        name: "SHP3051138",
        created_date: "2022-03-17T14:46:00.000+0000",
        load_status: "Assigned",
        origin: "Lavonia, Georgia",
        destination: "Medley, Florida",
        pro_number: null,
        expected_delivery_date: null,
        actual_delivery_date: null,
        revenue_cost: null,
        carrier: { id: "0011I000004movKQAQ", name: "R+L Carriers" },
        actual_pickup_date: null,
        expected_ship_date: "2022-03-18",
        mode_name: "LTL",
        magaya_record_url: null,
        pickup_carrier_number: null,
        po_number: "2232",
        dispatcher_internal: {
          id: "0051I000000SfgcQAC",
          name: "Technology Team",
          street: "4995 NW 72nd Ave Suite 200",
          city: "Miami",
          state: "Florida",
          zip_code: "33166",
          state_code: "FL",
          country_code: "US",
          mobile_phone: "+573004377830",
          email: "vmesa@shipwithglt.com",
        },
        cargo_summary: "5 Pallets (48.00x33.00x34.00 in), Instrument Transformers, 1816 lbs",
        billing_reference: "132123",
        line_items: [
          {
            name: "Item",
            description: "Instrument Transformers",
            dimension_units: "in",
            handling_unit_count: 5,
            handling_units: "Pallets",
            height: 34,
            length: 48,
            linear_feet: "12.0",
            nmfc_class: "70",
            weight_units: "lbs",
            weight: 1816,
            width: 33,
            pickup_stop: "a0t3s000003tfnpAAA",
            delivery_stop: "a0t3s000003tfnqAAA",
            hazardous_materials: false,
            hazmat_class_division: null,
            hazmat_number_type: null,
            hazmat_number: null,
            hazmat_packaging_group: null,
            hazmat_contact: null,
            nmfc_number: "63420-01",
            packaging_unit_count: null,
            packaging_units: null,
            density: 11.65,
            id: "a0g3s00000UtlPCAAZ",
            line_item_id: null,
            load_id: "a0j3s00000EaNZqAAN",
            user_height: null,
            user_length: null,
            user_width: null,
            user_weight: null,
            user_dimension_units: null,
            user_weight_units: null,
          },
        ],
        stops: [
          {
            name: "Stop 1",
            location_id: "0013s000015w2gNAAQ",
            address: "25 Hamburg Avenue, Lavonia, Georgia 30553",
            contact_info_id: "0033s00001BDh8VAAT",
            shipping_receiving_hours: "08:00-16:30",
            expected_date: "2022-03-18",
            appointmet_required: null,
            appointment_time: null,
            references: "Carrier Pickup Number: 60314171",
            instructions: null,
            is_dropoff: false,
            is_pickup: true,
            departure_date: null,
            stop_status: null,
            number: 1,
            state_code: null,
            zip_code: null,
            country_code: null,
            city: null,
            id: "a0t3s000003tfnpAAA",
          },
          {
            name: "Stop 2",
            location_id: "0011I00000FpvWsQAJ",
            address: "9372 NW 101st St, Medley, Florida 33178",
            contact_info_id: "0033s00001Ak5RTAAZ",
            shipping_receiving_hours: "09:00-17:00",
            expected_date: null,
            appointmet_required: null,
            appointment_time: null,
            references: null,
            instructions: null,
            is_dropoff: true,
            is_pickup: false,
            departure_date: null,
            stop_status: null,
            number: 2,
            state_code: null,
            zip_code: null,
            country_code: null,
            city: null,
            id: "a0t3s000003tfnqAAA",
          },
        ],
        quote_date: null,
        quote_total: 572.72,
        carrier_name: "R+L Carriers (LTL)",
        accessorials: [],
        mode_id: "a0k1I0000005NnyQAE",
        accounts: null,
        contacts: null,
        multiservice: false,
        cargo_value_for_insurance: null,
        insurance_required: false,
        pickup_date: null,
        delivery_date: null,
        request_history: "Requested: 3/17/2022 10:51 AM",
        tender_accepted_date: "2022-03-18T13:45:17.000+0000",
        source: null,
      },
      progressBarMax: 100,
    };
  },
  methods: {
    ceilDecimals(decimal) {
      return Math.ceil(decimal);
    },
  },
};
