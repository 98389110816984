/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */

export function increaseBrightness(rgbValues, percent) {
  return `#${
    ((0 | (1 << 8) + rgbValues.r + ((256 - rgbValues.r) * percent) / 100).toString(16)).slice(1)
  }${((0 | (1 << 8) + rgbValues.g + ((256 - rgbValues.g) * percent) / 100).toString(16)).slice(1)
  }${((0 | (1 << 8) + rgbValues.b + ((256 - rgbValues.b) * percent) / 100).toString(16)).slice(1)}`;
}

export function decreaseBrightness(rgbValues, percent) {
  return `#${
    ((0 | (1 << 8) + (rgbValues.r * (100 - percent)) / 100).toString(16)).substr(1)
  }${((0 | (1 << 8) + (rgbValues.g * (100 - percent)) / 100).toString(16)).substr(1)
  }${((0 | (1 << 8) + (rgbValues.b * (100 - percent)) / 100).toString(16)).substr(1)}`;
}

export function lightOrDark(color) {
  let r;
  let g;
  let b;
  let newColor;
  if (color.match(/^rgb/)) {
    newColor = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    [r] = color;
    [g] = color;
    [b] = color;
  } else {
    newColor = +(`0x${color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    )}`
    );
    r = newColor >> 16;
    g = newColor >> 8 & 255;
    b = newColor & 255;
  }
  const hsp = Math.sqrt(
    0.299 * (r * r)
        + 0.587 * (g * g)
        + 0.114 * (b * b)
  );
  if (hsp > 127.5) {
    return 'light';
  }
  return 'dark';
}

function parseHexToRgb(color) {
  let newHex = color.replace(/^\s*#|\s*$/g, '');
  if (newHex.length === 3) {
    newHex = newHex.replace(/(.)/g, '$1$1');
  }
  return {
    r: parseInt(newHex.slice(0, 2), 16),
    g: parseInt(newHex.slice(2, 4), 16),
    b: parseInt(newHex.slice(4, 6), 16)
  };
}

function modifyBrightness(color, colorBrightness) {
  const rgbValues = parseHexToRgb(color);
  if (colorBrightness === "light") {
    return decreaseBrightness(rgbValues, 10);
  }
  return increaseBrightness(rgbValues, 15);
}

export function generateHoverColor(color) {
  let newHoverColor;
  if (lightOrDark(color) === "light") {
    newHoverColor = modifyBrightness(color, "light");
  } else {
    newHoverColor = modifyBrightness(color, "dark");
  }
  return newHoverColor;
}
